
import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import BaseIconButton from '../base/BaseIconButton.vue'

@Component({
  name: 'CarouselControls',
  components: {
    BaseIconButton,
  },
})
export default class CarouselControls extends Vue {
  @Prop({ required: true }) variant! : 'stage-large' | 'stage-medium' | 'carousel'

  @Prop({ required: true }) length! : number

  @Prop({ required: true }) wrapperSpacings! : string

  @Prop({ required: true }) currentIndex! : number

  private setIndex (index : number) : void {
    if (this.currentIndex === index) return

    this.$emit('slide', index)
  }

  private slide (direction : 'prev' | 'next') : void {
    let newIndex = direction === 'prev' ? this.currentIndex - 1 : this.currentIndex + 1

    if (newIndex === this.length) {
      newIndex = 0
    }
    if (newIndex < 0) {
      newIndex = this.length - 1
    }

    this.setIndex(newIndex)
  }
}
